<template>
  <section id="Custom" class="my-xl-12">
    <v-container class="px-4 py-md-12 py-lg-12 mt-md-10 mt-lg-10 mt-xl-12">
      <v-responsive height="100%" width="100%">
        <v-row class="mx-0 mt-lg-5">
          <v-col cols="12" class="text-center pb-0">
            <span class="title-landing-section" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">¿Qué te ahorras?</span>
          </v-col>
        </v-row>
        <v-row class="mx-0 mt-md-10 mt-lg-10" justify="center" align="center">
          <v-col cols="4" sm="2" md="2" lg="2" class="text-center mx-md-3 pb-1" :class="{'pt-9': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
              <img
                :src="require('@/assets/Home/euro.svg')"
                class="white--text mx-auto"
                :width="$vuetify.breakpoint.xlOnly ? '117px' : $vuetify.breakpoint.mdAndUp ? '67px' : '57px'"
                :height="$vuetify.breakpoint.xlOnly ? '137px' : $vuetify.breakpoint.mdAndUp ? '87px' : '77px'"
              ></img>
          </v-col> 
          <v-col cols="4" sm="2" md="2" lg="2" class="text-center mx-md-3 pb-1" :class="{'pt-7': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
             <img
                :src="require('@/assets/Home/transfer.svg')"
                class="white--text mx-auto"
                :width="$vuetify.breakpoint.xlOnly ? '125px' : $vuetify.breakpoint.mdAndUp ? '75px' : '55px'"
                :height="$vuetify.breakpoint.xlOnly ? '123px' : $vuetify.breakpoint.mdAndUp ? '73px' : '53px'"
              ></img>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" class="text-center mx-md-3 pb-1" :class="{'pt-7': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
           <v-img
              :src="require('@/assets/Home/clock.svg')"
              class="white--text mx-auto"
              :width="$vuetify.breakpoint.xlOnly ? '121px' : $vuetify.breakpoint.mdAndUp ? '71px' : '61px'"
              :height="$vuetify.breakpoint.xlOnly ? '121px' : $vuetify.breakpoint.mdAndUp ? '71px' : '61px'"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="mx-0 mt-0" justify="center" align="center">
          <v-col cols="4" sm="2" md="2" lg="2" class="text-center mx-md-3" :class="{'pt-3': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
            <span class="title-saving" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Dinero</span>
          </v-col>
           <v-col cols="4" sm="2" md="2" lg="2" class="text-center mx-md-3" :class="{'pt-3': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }"></v-col>
          <v-col cols="4" sm="2" md="2" lg="2" class="text-center mx-md-3" :class="{'pt-3': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
            <span class="title-saving" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Tiempo</span>
          </v-col>
        </v-row>
         <v-row class="mx-0 mt-0" justify="center" align="center">
          <v-col cols="6" sm="4" md="4" lg="4" class="text-center mx-md-6" :class="{'pt-3': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
            <p class="description-saving" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
              Ya no tendrás que imprimir tus menús con el consiguiente coste que conlleva
            </p>
          </v-col>
          <v-col cols="6" sm="4" md="4" lg="4" class="text-center mx-md-6" :class="{'pt-3': $vuetify.breakpoint.lgAndDown, 'pt-5': $vuetify.breakpoint.xlOnly }">
            <p class="description-saving" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
              Ya no tendrás que imprimir tus menús con el consiguiente coste que conlleva
            </p>
          </v-col>
        </v-row>
      </v-responsive>
    </v-container>
  </section>
</template>

<script>
  export default {
    name: 'Saving',
  }
</script>
